<template>
    <pagination :title="title" :response="response" :entities="entities" :collapsable="true" :open="open" @toggleOpen="toggleOpen" :has-entities="true" :check-size="false" :hasLogoTitle="$slots.logo">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:title><slot name="logo"></slot> </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">name
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Actions</th>
        </template>
        <template v-slot:items>
            <tr v-for="entity in entities">
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    <div>{{ entity.option.title }}
                    </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <input type="checkbox" :value="entity.option.value" v-model="selected">
                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import StatusBadge from "@/components/StatusBadge.vue";
import FlagOrderAction from "@/v2/components/FlagOrderAction.vue";
import dayjs from "dayjs";
import Siren from "super-siren";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
export default {
  name: 'ClientRolePermissionsPagination',
    data(){
      return {
        viewUser: false,
          details: null,
          detailsOpen: false,
          open: false,
          selected: this.selectedPermissions
      }
    },
    components: {
        FormAction,
        DownloadPdfIconWithLink, FlagOrderAction, StatusBadge, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        initials() {
            if (this.details && this.details.properties['name']) {
                return this.details.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        },
    },
  props: {
    entities: {},
      response: {
        type: Object,
          default: null
      },
      title: {
        type: String,
          default: 'Permissions'
      },
      selectedPermissions:{
          default: null
      }
  },
    methods: {
        openDetails(entity) {
            this.$emit('setLoading');
            this.details = false;
            this.viewUser = true;
            this.getDetails(entity);
        },
        getDetails(entity) {
            let selfLink = entity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );
            Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                this.$emit('setDetails', response.body);
            }).catch(function () {

            });
        },
        showCreatedDaysAgo(orderEntity) {
            let createdDate = dayjs(orderEntity.entity.properties.get('created_at'));
            return dayjs.duration(createdDate.diff(dayjs(), 'seconds'), 'seconds').humanize(true)
        },
        showUpdatedDaysAgo(orderEntity) {
            let createdDate = dayjs(orderEntity.entity.properties.get('updated_at_unformatted'));
            return dayjs.duration(createdDate.diff(dayjs(), 'seconds'), 'seconds').humanize(true)
        },
        altViewClicked() {
            this.$emit('altViewClicked');
            this.viewUser = false;
        },
        toggleOpen() {
            this.open = !this.open;
        },
        reloadPermissions() {
            this.$emit('reloadPermissions');
        },
    },
    watch: {
        selected: {
            handler(value) {
                console.log(value);
                this.$emit("input", value);
            },
            deep: true
        },
        selectedPermissions: {
            handler(value) {
                console.log(value);
                this.selected = value;
            },
            deep: true
        },
    }
}
</script>
